<template>
  <v-dialog :value="true" persistent width="800px">
    <v-card>
      <v-card-title class="primary white--text">
        <span class="text-h5">{{ $t('sendEmail') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-checkbox
            v-for="att in attachments[item.bostamp]"
            :key="att.anexosstamp"
            v-model="selected"
            :label="att.resumo"
            :value="att.anexosstamp"
          />
        </v-container>
        <v-container>
          <p>{{ containerUMatric }}</p>
          <p>{{ containerUReboq }}</p>
          <template v-if="atCodeId">
            <p>{{ containerAtcodeid }}</p>
            <p>{{ containerXpddata }}</p>
            <p>{{ containerXpdhora }}</p>
          </template>
          <p>{{ containerUCepos }}</p>
          <p>{{ containerObrano }}</p>
        </v-container>
        <v-container>
          <validation-observer ref="observer" v-slot="{ passes }">
            <v-form @submit.prevent="passes(submitForm)">
              <validation-provider v-slot="{ errors }" name="clientEmails">
                <v-text-field
                  v-model="item.client_emails"
                  :prepend-inner-icon="icons.mdiEmail"
                  :label="$t('fields.clientEmails')"
                  :error-messages="errors"
                  :hint="$t('wot.emailSeparator')"
                  persistent-hint
                  outlined
                  dense
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="transporterEmails">
                <v-text-field
                  v-model="item.transporter_emails"
                  :prepend-inner-icon="icons.mdiEmail"
                  :label="$t('fields.transporterEmails')"
                  :error-messages="errors"
                  :hint="$t('wot.emailSeparator')"
                  persistent-hint
                  outlined
                  dense
                />
              </validation-provider>
            </v-form>
          </validation-observer>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="setEmailDialog(false)">{{ $t('aClose') }}</v-btn>
        <v-btn text color="primary" @click="clickSendEmails">{{ $t('aSend') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mdiEmail } from '@mdi/js';
import functions from '@/resources/functions';

export default {
  mixins: [functions],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: { mdiEmail },
      checkboxes: [
        { label: this.$tc('client', 1), value: 1 },
        { label: this.$tc('transporter', 1), value: 2 },
        { label: this.$tc('team', 2), value: 3 },
        { label: this.$tc('provider', 1), value: 4 },
      ],
      selected: [],
    };
  },
  computed: {
    ...mapGetters('loadOrders', ['attachments']),
    emails() {
      const arr1 = this.item.client_emails.split(';');
      const arr2 = this.item.transporter_emails.split(';');

      const emails = arr1.concat(arr2);

      return emails.filter((item) => item);
    },
    atCodeId() {
      return this.item.at_document?.atcodeid;
    },
    containerUMatric() {
      return `${this.$t('fields.u_matric')}: ${this.item.u_matric}`;
    },
    containerUReboq() {
      return `${this.$t('fields.u_reboq')}: ${this.item.u_reboq}`;
    },
    containerAtcodeid() {
      return `${this.$t('fields.atcodeid')}: ${this.atCodeId}`;
    },
    containerXpddata() {
      const xpddata = this.formatUnix(this.item.at_document.xpddata);

      return `${this.$t('fields.xpddata')}: ${xpddata}`;
    },
    containerXpdhora() {
      const { xpdhora } = this.item.at_document;

      return `${this.$t('fields.xpdhora')}: ${xpdhora}`;
    },
    containerUCepos() {
      return `${this.$t('fields.u_cepos')}: ${this.item.u_cepos}`;
    },
    containerObrano() {
      return `${this.$t('fields.obrano')}: ${this.item.obrano}`;
    },
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...mapMutations('loadOrders', ['setEmailDialog']),
    ...mapActions('loadOrders', ['sendEmails']),
    async clickSendEmails() {
      this.setLoading(true);

      await this.sendEmails({
        bostamp: this.item.bostamp, attachments: this.selected, emails: this.emails,
      });

      this.setLoading(false);
    },
  },
  mounted() {
    this.attachments[this.item.bostamp].forEach((row) => {
      this.selected.push(row.anexosstamp);
    });
  },
};
</script>
